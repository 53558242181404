<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte"
  import Manage from "$lib/admin/components/Manage.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Heading from '$lib/admin/components/blocks/helpers/Heading.svelte';

  export let props = null
  export let adminView = false
  const id = Math.random().toString(16)

  function addRepeatableField() {
    if(!Array.isArray(props.tableContent)) props.tableContent = []
    const field = [[]]
    props.tableContent.push(field)
    // TODO: using below function to update the preview does not seem to work
    props = props
  }

  function removeRepeatableField(fieldIndex) {
    props.tableContent.splice(fieldIndex, 1)
    // TODO: using below function to update the preview does not seem to work
    props = props
  }

  function createHeaders() {
    if(!Array.isArray(props.tableHeaders)) props.tableHeaders = ["test"]
    props = props
  }
</script>

{#if adminView}
  <Group addClass="form-item">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <Group><label>Table headers</label></Group>
    {#if props.tableHeaders && props.tableSingleHeader}
      <Group>
        <Input
          placeholder="Header text"
          bind:value={props.tableHeaders[0]}
          on:keyup />
      </Group>
    {:else if props.tableHeaders}
      <Group colspan={ props.tableColumns }>
        {#each {length: props.tableColumns} as _, i}
          <Input
            placeholder="Header text"
            bind:value={props.tableHeaders[i]}
            on:keyup />
        {/each}
      </Group>
    {:else}
      <!-- TODO:
        this is a super hacky way of creating the tableHeaders array.
        Possible to have this done when adding the component directly?
      -->
      <button on:click={() => createHeaders()}>Create headers</button>
    {/if}
  </Group>
  <Group colspan="1-11" addClass="form-item">
    <Input
      label="Single table header"
      type="checkbox"
      bind:checked={props.tableSingleHeader} />
  </Group>
  <Group colspan="8-4" addClass="form-item">
    <!-- TODO: min/max here does not seem to be working.. -->
    <Input
      label="Table columns"
      id={`columns-${id}`}
      type="number"
      min="2"
      max="3"
      bind:value={props.tableColumns} />
  </Group>
  {#if props.tableContent}
    {#each props.tableContent as item, fieldIndex}
      <Group colspan="1-auto" addClass="form-item">
        <Group colspan={ props.tableColumns }>
          {#each {length: props.tableColumns} as _, i}
            <Input
              placeholder="Cell content {i + 1}"
              bind:value={item[i]}
              on:keyup />
          {/each}
        </Group>
        <Button
          addClass="secondary"
          onlyIcon
          icon="trash-2"
          on:click={() => removeRepeatableField(fieldIndex)}
          />
      </Group>
    {/each}
  {/if}
  <Group addClass="form-item manage">
    <Manage>
      <Button addClass="primary" on:click={() => addRepeatableField()}>Add table row</Button>
    </Manage>
  </Group>
{:else}
  <div class="block block-table-basic" class:scroll={ props.tableColumns > 2}>
    <div class="container">
      <div class="grid">
        <div class="g-col-12 g-start-1 g-col-md-10 g-start-md-2">
          <Heading {props} />

          <div class="table-container">
            <table>
              <thead>
                <tr>
                  {#if props.tableSingleHeader}
                    <th colspan="{ props.tableColumns }">{ props.tableHeaders }</th>
                  {:else}
                    {#if props.tableHeaders}
                      {#each props?.tableHeaders as header}
                        <th>{ header }</th>
                      {/each}
                    {/if}
                  {/if}
                </tr>
              </thead>
              <tbody>
                {#if props.tableContent}
                  {#each props.tableContent as row}
                    <tr>
                      {#each row as cellContent}
                        <td>{@html cellContent }</td>
                      {/each}
                    </tr>
                  {/each}
                {/if}
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
{/if}