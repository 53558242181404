<script lang="ts">
  import Skeleton from "$lib/main/components/Skeleton.svelte";

  export let totalScore: number;
  export let score: number;
  export let loading: boolean = false;
  const maxVisualBlocks = 5;

  $: normalizedScore = (score / totalScore) * maxVisualBlocks;

  $: blocks = Array.from({ length: maxVisualBlocks }, (_, i) => {
    const fullCoverage = Math.min(normalizedScore - i, 1);
    return Math.max(0, fullCoverage);
  });

  const determineColor = (score: number, totalScore: number): string => {
    const ratio = score / totalScore;
    if (ratio < 0.33) return "red";
    else if (ratio < 0.66) return "orange";
    else return "green";
  };

  $: color = determineColor(score, totalScore);
</script>

<div class="rating-bar">
  <div class="bar">
    {#each blocks as block, i}
      <div
        class="block"
        style="--color: {color}; --fill: {Math.floor(block * 100)}%;"
      >
        <div
          class="fill"
          style="width: var(--fill); background-color: var(--color);"
        ></div>
      </div>
    {/each}
  </div>
  <div style="font-weight: 700">
    {#if loading}
      <Skeleton width="23px" height="14px" />
    {:else}
      {normalizedScore.toFixed(1)}
    {/if}
  </div>
</div>

<style>
  .rating-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    height: 10px;
  }
  .bar {
    display: flex;
    width: 100%;
    height: 8px;
    overflow: hidden;
  }
  .block {
    width: 20%;
    height: 100%;
    position: relative;
    background-color: #ccc;
  }
  .fill {
    height: 100%;
    transition: width 0.3s ease;
    border-left: 1px solid white;
  }
  .block:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .block:first-child,
  .block:first-child .fill {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: none;
  }
</style>
