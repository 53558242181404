<script lang="ts">
    type VariantType = "warning" | "danger" | "default" | "success";

    export let rating: number = 0;
    export let variant: VariantType = "default";

    const generateStars = () => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push({ highlighted: i < rating });
        }
        return stars;
    };
</script>

{#each generateStars() as star, i}
    <span class="star {star.highlighted ? 'highlighted' : ''} {variant}"
        >&#9733;</span
    >
{/each}

<style>
    .star {
        font-size: 12px;
        color: #a5dc87;
    }

    .highlighted {
        color: #65c82d;
    }

    .warning {
        color: #ffdd80;
    }
    .warning.highlighted {
        color: #ffc107;
    }

    .danger {
        color: #db847b;
    }
    .danger.highlighted {
        color: #c0392b;
    }

    .success {
        color: #a5dc87;
    }
    .success.highlighted {
        color: #65c82d;
    }
</style>
