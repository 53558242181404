export function isExceedingWordLimit(text: string, wordLimit: number) {
  const words = text.split(" ");
  return words.length > wordLimit;
}

export function truncateText(text: string, wordLimit: number) {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  } else {
    return text;
  }
}
