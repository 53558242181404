<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from '$lib/admin/components/Input.svelte';

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group colspan="8-4" addClass="form-item">
    <Input label="YouTube video code (11 chars)" placeholder="Insert YouTube code" bind:value={props.videoCode} on:keyup />
  </Group>
  <Group colspan="2-10" addClass="form-item">
    <Input label="Caption" placeholder="Write a caption" bind:value={props.videoCaption} on:keyup />
  </Group>
{:else}
  {#if props.videoCode}
    <div class="block block-video">
      <div class="container">
        <div class="grid">
          <div class="g-col-12 g-start-1 g-col-md-10 g-start-md-2">
            <div class="video-frame">
              <iframe
                width="560"
                height="315"
                title={props.videoCaption}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                src="https://www.youtube.com/embed/{props.videoCode}?controls=0">
              </iframe>
              <!-- <lite-youtube videoid="{props.videoCode}" playlabel="{props.videoCaption}" style="background-image: url('https://i.ytimg.com/vi/{props.videoCode}/hqdefault.jpg'); max-width: 100%; border-radius: 6px;">
                <button type="button" class="lty-playbtn">
                  <span class="lyt-visually-hidden">Play {props.videoCaption}</span>
                </button>
              </lite-youtube> -->
            </div>
            {#if props.videoCaption}
              <p>{@html props.videoCaption}</p>
            {/if}
          </div>
        </div>
      </div>
    </div>
  {/if}
{/if}