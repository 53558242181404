export enum RankingCategoriesEnum {
  PAYMENTS = "payments",
  SUPPORT = "support",
  GAMES_EXPERIENCE = "games_experience",
  GAMES_SORTIMENT = "games_sortiment",
  OVERALL = "overall",
}

export const RankingTitlesEnum = {
  [RankingCategoriesEnum.OVERALL]: "Overall Rating",
  [RankingCategoriesEnum.PAYMENTS]: "Payments",
  [RankingCategoriesEnum.SUPPORT]: "Support",
  [RankingCategoriesEnum.GAMES_EXPERIENCE]: "Games Experience",
  [RankingCategoriesEnum.GAMES_SORTIMENT]: "Games Sortiment",
} as const;

export type RankingCategory = keyof typeof RankingCategoriesEnum;
export type RankingTitle = (typeof RankingTitlesEnum)[RankingCategoriesEnum];
