<script>
  import { enhance } from "$app/forms";
  import Icons from "$lib/main/components/Icons.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
  import Draggable from "$lib/admin/components/Draggable.svelte";

  export let post = null;
  export let index = null;
  export let open = null;
  export let addClass = null;

  export let action = null;
  export let draggable = false;
  export let type = "basic";
  export let link = "/";
  export let anchor = null;
  export let description = null;
  export let image = null;
  export let useEnhance = null;

  let toggleModal = false;
</script>

<div class={"card " + type + (addClass ? " " + addClass : "")} id={`block-${index+1}`}>
  {#if $$slots.header}
    <Draggable {draggable} bind:post {index} bind:open>
      <header class="flex-sb-c">
        <h4><slot name="header" /></h4>
        {#if $$slots.tooltip}
          <button
            on:click={() => {
              toggleModal = !toggleModal;
            }}><Icons name="help-circle" /></button
          >
        {/if}
        {#if $$slots.manage}
          <slot name="manage" />
        {/if}
      </header>
    </Draggable>
  {/if}
  {#if type == "basic"}
    <div class="content"><slot /></div>
  {:else if type == "none"}
    <slot />
  {:else if type == "form"}
    {#if action && useEnhance}
      <form {action} use:enhance={useEnhance} method="POST">
        <slot />
      </form>
    {:else if action && !useEnhance}
      <form {action} method="POST">
        <slot />
      </form>
    {:else if !action && useEnhance}
      <form use:enhance={useEnhance} method="POST">
        <slot />
      </form>
    {:else}
      <form on:submit|preventDefault>
        <slot />
      </form>
    {/if}
  {:else if type == "link"}
    <a href={link}>
      <div class="top flex-sb-c">
        {#if image}
          <img src={image} alt={anchor} />
        {:else}
          <span>{anchor}</span>
        {/if}
        <Icons
          color="#707070"
          width="24px"
          height="24px"
          name="chevron-right"
        />
      </div>
      <div class="bottom">
        {#if description}
          <span>{description}</span>
        {:else if anchor}
          <span>{anchor}</span>
        {/if}
      </div>
    </a>
  {/if}
</div>

{#if $$slots.tooltip}
  <Modal bind:toggleModal>
    <svelte:fragment slot="header"
      ><slot name="header" /> Tooltip</svelte:fragment
    >
    <slot name="tooltip" />
  </Modal>
{/if}