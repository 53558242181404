<script>
  import Group from '$lib/admin/components/Group.svelte';
  import Input from '$lib/admin/components/Input.svelte';
  import SunEditorForm from '$lib/admin/components/content/SunEditorForm.svelte';
  import Manage from '$lib/admin/components/Manage.svelte';
  import Button from '$lib/main/components/Button.svelte';

  export let props = null
  export let adminView = false

  function addRepeatableField() {
    if(!Array.isArray(props.content)) props.content = []
    const field = {header: "", content: ""}
    props.content.push(field)
    props = props
  }

  function removeRepeatableField(fieldIndex) {
    props.content.splice(fieldIndex, 1)
    props = props
  }
</script>

{#if adminView}
  {#if props && props.content}
    {#each props.content as item, fieldIndex}
      <Group addClass="separator">
        <Group colspan="4-8" addClass="form-item">
          <Input label="Card heading" bind:value={item.header} placeholder="Card heading" on:keyup/>
        </Group>
        <Group addClass="form-item">
          <SunEditorForm bind:text={item.content} placeholder="Card body" on:keyup />
        </Group>
        <Group addClass="form-item manage">
          <Manage>
            <Button addClass="primary" on:click={() => removeRepeatableField(fieldIndex)}>Remove card item</Button>
          </Manage>
        </Group>
      </Group>
    {/each}
  {/if}
  <Group addClass="form-item manage">
    <Manage>
      <Button addClass="secondary" on:click={() => addRepeatableField()}>Add card item</Button>
    </Manage>
  </Group>
{:else}
  <div class="row">
    {#if props.content}
      <ul class:single="{props.content.length < 2}">
        {#each props.content as card}
          <li>
            {#if card.header}
              <span class="title">{card.header}</span>
            {/if}
            {#if card.content}
              <span class="content">{@html card.content}</span>
            {/if}
            {#if !card.header && !card.content}
              <span class="content">No cards added yet..</span>
            {/if}
          </li>
        {:else}
          <li><span class="content">No cards added yet..</span></li>
        {/each}
      </ul>
    {:else}
      <p>No cards added yet..</p>
    {/if}
  </div>
{/if}

<style>
  .row {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  ul.single li{
    width: 100%;
  }
  li {
    width: calc(50% - (15px / 2));
    background: #f2f2f2;
    padding: 15px;
    margin: 0 0 1.5em 0;
    display: flex;
    flex-direction: column;
  }
  .title {
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    color: #262626;
    margin: 0 0 5px 0;
  }
  .content {
    font-size: 14px;
    color: #222;
  }
</style>