<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { orgOperators } from "$lib/services/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import supabase from "$lib/services/db";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Carousel from "$lib/main/components/Carousel.svelte";
  import FeedbackCard from "$lib/admin/components//blocks/helpers/FeedbackCard.svelte";
  import type { OperatorFeedback } from "@/src/types/components/operators/OperatorFeedback";
  import Translate from "../helpers/Translate.svelte";

  interface FeedbackProps {
    operator: Operator;
    operatorId: number;
  }

  export let props: FeedbackProps;
  export let adminView: boolean;

  let operators = $orgOperators as Operator[];
  let slides: OperatorFeedback[] = [];

  const dispatch = createEventDispatcher();

  const loadOperatorFeedback = async (id: number) => {
    const { data } = await supabase
      .from("operator_feedback")
      .select("*")
      .eq("operator_id", id)
      .order("featured", { ascending: false })
      .order("date", { ascending: false });

    slides = data as OperatorFeedback[];
  };

  onMount(async () => {
    await loadOperatorFeedback(props.operatorId);
  });
</script>

{#if adminView}
  <Group colspan="4-8">
    <Input
      label="Operator"
      type="select"
      bind:value={props.operatorId}
      on:change={() => dispatch("keyup")}
    >
      <option value="">~not set~</option>
      {#each operators as operator}
        <option value={operator.id}
          >{operator.name ?? "Couldn't find metadata"}</option
        >
      {/each}
    </Input>
  </Group>
{:else}
  <div class="container">
    <div class="grid">
      <div class="g-col-12">
        <p class="review-text">
          {#if slides?.length}
            {slides.length} <Translate text="user comments"></Translate>
          {/if}
        </p>
        {#if slides?.length}
          <Carousel size={slides?.length}>
            {#each slides as slide}
              <FeedbackCard
                feedback={slide}
                operatorName={props?.operator?.name || ""}
              />
            {/each}
          </Carousel>
        {/if}
      </div>
    </div>
  </div>
{/if}

<style>
  .review-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 10px;
  }
</style>
