<script lang="ts">
  import { onMount } from "svelte";
  import AdminView from "./AdminView.svelte";
  import TabMode from "./TabMode.svelte";
  import AccordionMode from "./AccordionMode.svelte";
  import type { Tab } from "@/src/types/components/DataTable";
  import { fetchData } from "./dataFetcher";

  export let props: {
    hideHeader: boolean;
    tabs: Tab[];
    isAccordion?: boolean;
    bgColor?: string;
  };
  export let adminView = false;
  export let hasContainer = false;

  let tabs: Tab[] = props.tabs || [
    {
      title: "",
      selectedColumns: [],
      endpoint: "",
      additionalArgs: {},
      endpointSelected: false,
    },
  ];

  let activeTab = 0;
  let data: any[] = [];
  let dataKeys: string[] = [];
  let isLoading = true;
  let hideHeader = props.hideHeader || false;
  let isAccordion = props.isAccordion || false;
  let bgColor = props.bgColor || "";
  let accordionData: Record<number, any[]> = {};

  async function handleFetchData(tabIndex: number) {
    isLoading = true;
    const result = await fetchData(tabIndex, tabs, data, dataKeys, isLoading, accordionData);
    data = result.data;
    dataKeys = result.dataKeys;
    isLoading = result.isLoading;
    accordionData = result.accordionData;
    tabs = [...tabs];
  }

  onMount(async () => {
    if (tabs[activeTab] && tabs[activeTab].endpoint) {
      await handleFetchData(activeTab);
    }
  });

  function handleTabsChange(event: CustomEvent<Tab[]>) {
    tabs = event.detail;
    props.tabs = [...tabs];
  }

  function handleBackgroundChange(event: CustomEvent<{ bgColor: string }>) {
    props.bgColor = event.detail.bgColor;
  }

  function handleActiveTabChange(event: CustomEvent<number>) {
    activeTab = event.detail;
    handleFetchData(activeTab);
  }

  function handleAccordionChange(event: CustomEvent<boolean>) {
    isAccordion = event.detail;
    props.isAccordion = isAccordion;
  }

  function handleHideHeaderChange(event: CustomEvent<boolean>) {
    hideHeader = event.detail;
    props.hideHeader = hideHeader;
  }

  function handleFetchDataRequest(event: CustomEvent<number>) {
    handleFetchData(event.detail);
  }

  $: props.tabs = tabs;
  $: props.isAccordion = isAccordion;
  $: props.bgColor = bgColor;
  $: props.hideHeader = hideHeader;
</script>

{#if adminView}
  <AdminView
    {tabs}
    {isAccordion}
    {bgColor}
    {hideHeader}
    {dataKeys}
    {data}
    on:backgroundChange={handleBackgroundChange}
    on:tabsChange={handleTabsChange}
    on:accordionChange={handleAccordionChange}
    on:hideHeaderChange={handleHideHeaderChange}
    on:fetchData={handleFetchDataRequest} />
{:else if isAccordion}
  <div style="background: {bgColor};">
    <AccordionMode {tabs} {hideHeader} {accordionData} on:fetchData={handleFetchDataRequest} />
  </div>
{:else}
  <div style="background: {bgColor};">
    <div class="container">
      <TabMode
        {tabs}
        {hideHeader}
        {data}
        {isLoading}
        {activeTab}
        {hasContainer}
        on:activeTabChange={handleActiveTabChange} />
    </div>
  </div>
{/if}
