import type { Tab } from "@/src/types/components/DataTable";
import type { Tournament, LeaderboardResponse, LeaderboardEntry } from "@/src/types/tournaments";

import {
  fetchTournaments,
  fetchLiveTournaments,
  fetchTournamentLeaderBoard,
  fetchFeatureTournaments,
  fetchRunningTournament,
  fetchAllFinishedTournaments,
} from "$lib/services/tournaments/endpoints";
import supabase from "$lib/services/db";

type DataEntry = Tournament | LeaderboardEntry | Record<string, any>;

export async function fetchData(
  activeTab: number,
  tabs: Tab[],
  data: (Tournament | LeaderboardEntry | Record<string, any>)[],
  dataKeys: string[],
  isLoading: boolean,
  accordionData: Record<number, (Tournament | LeaderboardEntry | Record<string, any>)[]>,
): Promise<{
  data: (Tournament | LeaderboardEntry | Record<string, any>)[];
  dataKeys: string[];
  isLoading: boolean;
  accordionData: Record<number, (Tournament | LeaderboardEntry | Record<string, any>)[]>;
}> {
  isLoading = true;
  try {
    const currentTab = tabs[activeTab];
    if (!currentTab.endpoint) {
      console.error("No endpoint selected for the current tab");
      return { data, dataKeys, isLoading, accordionData };
    }

    const result = await getDataByEndpoint(currentTab.endpoint, currentTab.additionalArgs);
    let fetchedData: (Tournament | LeaderboardEntry | Record<string, any>)[];

    if (currentTab.endpoint === "leaderboard") {
      if (Array.isArray(result)) {
        // Check if the leaderboard is truly empty
        const isEmptyLeaderboard =
          result.length === 0 ||
          (result.length === 1 &&
            result[0].spinsMade === 0 &&
            result[0].playerScore === "0" &&
            !result[0].displayPrize);

        if (isEmptyLeaderboard) {
          fetchedData = [
            {
              position: "-",
              playerName: "No scores yet",
              playerScore: "-",
              totalSpins: result[0]?.totalSpins || 50,
              isEmptyLeaderboard: true,
            },
          ];
        } else {
          fetchedData = result.map((entry) => ({
            ...entry,
            isEmptyLeaderboard: false,
          }));
        }
      } else if (result && typeof result === "object" && "data" in result) {
        const leaderboardResponse = result as LeaderboardResponse;
        if (leaderboardResponse.data.scoreboard === false) {
          fetchedData = [
            {
              position: "-",
              playerName: "No players yet",
              playerScore: "-",
              totalSpins: leaderboardResponse.data.totalSpins,
              isEmptyLeaderboard: true,
            },
          ];
        } else {
          fetchedData = leaderboardResponse.data.scoreboard.map((entry) => ({
            ...entry,
            isEmptyLeaderboard: false,
          }));
        }
      } else {
        fetchedData = [];
      }
    } else if (Array.isArray(result)) {
      fetchedData = result;
    } else {
      fetchedData = [result];
    }

    if (tabs[activeTab].isOpen) {
      accordionData[activeTab] = fetchedData;
    } else {
      data = fetchedData;
    }

    if (fetchedData.length > 0) {
      dataKeys = Object.keys(fetchedData[0]);

      if (currentTab.selectedColumns.length === 0) {
        currentTab.selectedColumns = dataKeys.slice(0, 5).map((key) => ({ key, displayName: key }));
      }
      currentTab.selectedColumns = currentTab.selectedColumns.filter((col) => dataKeys.includes(col.key));
    }

    currentTab.endpointSelected = true;
    isLoading = false;
  } catch (error) {
    console.error("Error fetching data:", error);
    isLoading = false;
  }

  return { data, dataKeys, isLoading, accordionData };
}

interface EndpointArgs {
  tournamentId?: number;
  tournamentIdList?: number | null;
  username?: string;
}

export async function getDataByEndpoint(
  endpoint?: string,
  additionalArgs?: EndpointArgs,
): Promise<Tournament[] | LeaderboardResponse | Tournament> {
  switch (endpoint) {
    case "live":
      return await fetchLiveTournaments();
    case "results":
      return await fetchAllFinishedTournaments();
    case "leaderboard":
      if (additionalArgs?.tournamentId !== undefined) {
        return await fetchTournamentLeaderBoard(additionalArgs.tournamentId);
      }
      throw new Error("Tournament ID is required for leaderboard endpoint");
    case "runningtournament":
      if (additionalArgs?.tournamentId && additionalArgs?.username !== undefined) {
        return await fetchRunningTournament(additionalArgs.tournamentId, additionalArgs.username);
      }
      throw new Error("Tournament ID and username are required for runningtournament endpoint");
    case "featured":
      if (additionalArgs?.tournamentIdList !== undefined && additionalArgs.tournamentIdList !== null) {
        return await fetchFeatureTournaments(additionalArgs.tournamentIdList);
      }
      throw new Error("Tournament ID list is required for featured endpoint");
    default:
      return await fetchTournaments();
  }
}

export async function getTournamentsPageData(filter: string, position: number = 1) {
  try {
    const { data } = await supabase.from("content").select("content").eq("slug", "/turneringar").single();

    const block = data?.content?.filter((block: any) => block.blockType === filter);
    return block?.[position - 1] ?? null;
  } catch (error) {
    console.error("Failed to fetch tournaments page:", error);
  }
}
