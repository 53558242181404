<script>
  import { postData, project, orgCasinoGames, postCategories, orgOperators, postOperators } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import supabase from "$lib/services/db";
	import { createEventDispatcher } from "svelte";
  import Icons from '$lib/main/components/Icons.svelte';
  import { page } from '$app/stores';

  import { dateFormatter } from "$lib/services/utils";

  export let adminView = false;
  export let props = null;
  const dispatch = createEventDispatcher();

  // If on the front-end, it gets the data injected into it's `props`. But if we are in the preview tool, the data needs to be fetched dynamically
  let postList
  let gameProviders
  let postListFilterArray = [];

  $: {
    // need to check if postList is empty so as to not create an infinite loop of PostListData fetching
    if (props && !postList) {
      postList = props.postListData
      ? props.postListData
      : props.postListCategory
      ? getCategoryPosts(props.postListCategory)
      : null;
      // TODO: add a value for props.sorting; can choose type of sorting
      if (props && props.postListType === 'slots' && postList && postList.length > 1) {
        postList.sort(function(a,b) {
          return (a.title > b.title)
            ? 1
            : (
              (b.title > a.title)
              ? -1
              : 0
            )
        })
      }
    }
  }

  if (props && !props.limit) {
    props.limit = 3
  }

  // gameProviders = [...new Set($orgCasinoGames.map(x => x.game_provider?.name))]

  $: currentPage = $page?.params?.slug?.split('/')?.length > 1 ? Number($page.params.slug.split('/')[1]) : 1;
  let totalPages = props.totalPages ? props.totalPages : 10
  let paginationLimit = 5;
  let offset = Math.floor((paginationLimit / 2) + 1)

  async function getCategoryPosts(categoryPageId) {
    let query = supabase
      .from("content")
      .select()
      .eq("parent_id", categoryPageId)
      .eq("project_key", $project.key)
      .eq("is_published", true)
      .order("created_at", { ascending: false })
      .limit(props.limit)
    if (props.postListFilter) {
      if (props.postListCategory === $project.data?.categoryPages.slots.id) {
        // check if postListFilter is for an operator
        if ($postOperators?.some(obj => obj.id === props.postListFilter)) {
          const operatorName = $postOperators.find(obj => obj.id === props.postListFilter).title
          postListFilterArray = $orgOperators.filter(obj => obj.name === operatorName)[0]?.data.gameProviders
        } else {
          postListFilterArray = []
          postListFilterArray.push(props.postListFilter)
        }
        let filterArray = []
        if ($orgCasinoGames) {
          filterArray = $orgCasinoGames.filter(obj => postListFilterArray.includes(obj.game_provider?.name)).map(obj => obj.name)
        }
        query = query.in('title', filterArray)
      }
      if (props.postListCategory == $project.data?.categoryPages.news.id && props.postListFilter) {
        query = query.contains('tags', props.postListFilter)
      }
    }
    const { data, error } = await query
    postList = data
  }

  async function updatePostList(categoryPageId) {
    const { data, error } = await supabase
      .from("content")
      .select()
      .eq("parent_id", categoryPageId)
      .eq("project_key", $project.key)
      .eq("is_published", true)
      .order("created_at", { ascending: false })
      .limit(props.limit)
      .range(((currentPage - 1) * props.limit), (((currentPage) * props.limit) - 1))
    postList = data
  }
</script>

{#if adminView}
  <Group addClass="form-item">
    <Group colspan="2">
      <Input
        label="Post list type"
        type="select"
        bind:value={props.postListType}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        <option value="posts">Posts</option>
        <option value="games">Games</option>
        <option value="slots">Slots</option>
        <option value="game-providers">Game Providers</option>
      </Input>
      <Input
        label="Post list style"
        type="select"
        bind:value={props.postListStyle}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        <option value="cards">Cards</option>
      </Input>
    </Group>
    <!-- TODO: this is currently not reactive at all in the back-end. You need to save and refresh the content to be able to change this for some reason.. -->
    <Group colspan="4-8">
      <Input
        label="Post category"
        type="select"
        bind:value={props.postListCategory}
        on:change={() => dispatch("keyup")}>
        {#if $postCategories}
          <option value="">~not set~</option>
          {#each $postCategories as category}
            <option value={category.id}>{category.slug}</option>
          {/each}
        {/if}
      </Input>
    </Group>
    {#if
      $project.data &&
      $project.data.categoryPages && (
      props.postListCategory == $project.data.categoryPages.slots.id
      || props.postListCategory == $project.data.categoryPages.news.id)}
      {#if props.postListCategory == $project.data.categoryPages.slots.id}
      <Group colspan="4-8">
        <Input
          label="Post filter (game provider)"
          type="select"
          bind:value={props.postListFilter}
          on:change={() => dispatch("keyup")}>
          <option value="">All</option>
          {#if gameProviders}
            {#each gameProviders as item}
              <option value={item}>{item}</option>
            {/each}
          {/if}
        </Input>
      </Group>
      {/if}
      <Group colspan="4-8">
        <Input
          label="Post filter (operator)"
          type="select"
          bind:value={props.postListFilter}
          on:change={() => dispatch("keyup")}>
          <option value="">All</option>
          <option value={$postData.id}>Self</option>
          {#if $postOperators}
            {#each $postOperators as operator}
              <option value={operator.id}>{operator.title}</option>
            {/each}
          {/if}
        </Input>
      </Group>
    {/if}
    <Group colspan="10-2">
      <Input
        label="Posts length"
        type="number"
        min="1"
        max="10"
        on:keyup
        bind:value={props.limit} />
    </Group>
  </Group>
{:else}
  {#if postList?.length > 0 || props.limit < 3}
    {#if props.postListType && props.postListStyle}
      <Block blockClass="block-post-list" {props}>
        <Heading {props} />

        <div class={ props.postListType + " " + props.postListStyle }>
          {#if postList?.length && postList}
            {#each postList as post, index}
              {#if index < props.limit}
                <div class={`card card-${index + 1}`}>
                  <a href={post.slug} title={post.title}>
                    {#if
                      props.postListType == "slots"
                      || props.postListType == "games"}
                      {@const slot = $orgCasinoGames?.find(x => x.name === post.title)}
                      {#if slot}
                        <img
                          class:fallback={!post.image_featured}
                          loading="lazy"
                          alt={slot.name}
                          src={
                            post.image_featured
                            ? (props.postListType == "slots"
                              ? ($project.data?.imageFolders?.casinoGames + post.slug.split("/").pop() + ".jpg?fit=crop&w=300&h=200&q=80&auto=format")
                              : ($project.data?.imageFolders?.media + post.image_featured + "?fit=crop&w=300&h=200&q=80&auto=format"))
                            : $project.data?.imageFolders?.assets + $project.name_lower + "-logo.png?q=80&auto=format"}
                          on:error={ev => ev.target.src = `${$project.data?.imageFolders?.assets}/casinofeber-banner.png?w=300&h=200&q=80&auto=format`}>
                        <div class="info">
                          <span class="post-header">{slot.name}</span>
                          <span class="software">{slot.game_provider?.name ?? "N/A"}</span>
                          <span class="rtp">{(slot.data?.rtp ?? "N/A ") + "% RTP"}</span>
                          <span class="read-more"><Translate text="Read more" /></span>
                        </div>
                      {:else}
                        <p>Couldn't find this game..</p>
                      {/if}
                    {:else if props.postListType == "game-providers"}
                      <img
                        loading="lazy"
                        alt={post.title}
                        src={$project.data?.imageFolders?.gameProviders + post.slug.split("/").pop() + "-logo.png?q=80&auto=format"}>
                      <div class="info">
                        <span class="post-header">{post.title}</span>
                      </div>
                    {:else}
                      <img
                        class:fallback={!post.image_featured}
                        loading="lazy"
                        alt={post.title}
                        src={post.image_featured ? ($project.data?.imageFolders?.media + post.image_featured + "?fit=crop&w=300&h=200&q=80&auto=format") : $project.data?.imageFolders?.assets + $project.name_lower + "-logo.png?q=80&auto=format"}
                        on:error={ev => ev.target.src = `${$project.data?.imageFolders?.assets}/casinofeber-banner.png?w=300&h=200&q=80&auto=format`}>
                      <div class="info">
                        <span class="post-header">{post.title}</span>
                        <span class="post-preview">{post.seo?.meta_description}</span>
                        <span class="post-date">{dateFormatter(post.created_at, $project.settings.langAlt)}</span>
                        <span class="read-more"><Translate text="Read news" /></span>
                      </div>
                    {/if}
                  </a>
                </div>
              {/if}
            {/each}
          {:else}
            <div><Translate text="Found no posts" /></div>
          {/if}
        </div>

        <!-- TODO: move this to links component or regular button? -->
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            {#if props.linkAnchor && props.linkUrl}
              <a class="more" href={props.linkUrl}>{props.linkAnchor}</a>
            {/if}
          </div>
        </div>

        {#if props.addPagination && 3 < 2}
          <!-- This pagination is used for when you NOT want to navigate to a different URL for pagination -->
          <div class="pagination">
            <div class="pagination-holder">
              <button on:click={() => {
                if (currentPage > 0) {
                  currentPage -= 1
                  updatePostList(props.postListCategory)
                }
              }}><Icons name="chevron-left" strokeWidth="2" /></button>
              {#each {length: paginationLimit} as _, i}
                {@const linkPage = (currentPage > offset)
                  ? (currentPage + i - offset)
                  : i}
                <button
                  class:current={linkPage == currentPage}
                  on:click={() => {
                    if (linkPage != currentPage) {
                      currentPage = linkPage
                      updatePostList(props.postListCategory)
                    }
                  }}>{ linkPage + 1 }</button>
              {/each}
              <button on:click={() => {
                if (currentPage < totalPages - 2) {
                  currentPage += 1
                  updatePostList(props.postListCategory)
                }
              }}><Icons name="chevron-right" strokeWidth="2" /></button>
            </div>
          </div>
        {:else if props.addPagination}
          <!-- TODO: incredibly shaky built atm; preferably only expose these in the database, i.e. do not allow this to be used in the front-end just yet -->
          <!-- TODO: currently hardcoded for /news and /nyheter -->
          <div class="pagination">
            <div class="pagination-holder">
              {#if currentPage > 4}
                <a href={`/${$project.data?.categoryPages.news.slug}`}>«</a>
              {/if}
              <a
                class:disabled={ currentPage == 1}
                href={ currentPage == 1 ? null : `/${$project.data?.categoryPages.news.slug}` + (currentPage > 2 ? "/" + (currentPage - 1) : "") }>
                <Icons name="chevron-left" strokeWidth="2" /></a>
              {#each {length: paginationLimit} as _, i}
                {@const linkPage = (currentPage > offset)
                  ? (currentPage + i - offset + 1)
                  : i + 1}
                {#if linkPage < totalPages + 1}
                <a href={ `/${$project.data?.categoryPages.news.slug}` + (linkPage > 1 ? "/" + linkPage : "")}
                  class:current={linkPage == currentPage}>{ linkPage }</a>
                {/if}
              {/each}
              <a
                class:disabled={ currentPage >= totalPages }
                href={ `/${$project.data?.categoryPages.news.slug}/` + (currentPage < totalPages ? (currentPage + 1) : currentPage) }>
                <Icons name="chevron-right" strokeWidth="2" /></a>
              {#if currentPage < (totalPages - 4)}
                <a href={`/${$project.data?.categoryPages.news.slug}/${totalPages}`}>»</a>
              {/if}
            </div>
          </div>
        {/if}
      </Block>
    {:else}
      <p>No type and/or style set..</p>
    {/if}
  {/if}
{/if}