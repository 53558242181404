export const sanitizeTournamentName = (name?: string): string => {
  if (!name) return "Unnamed Tournament";
  const regex = /\[(\d+)\]\s*(.*?)\s*\[(\d+)\]/;
  const match = regex.exec(name);
  return match ? match[2] : name;
};

export const parseTournamentName = (name: string) => {
  const regex = /\[(\d+)\]\s*(.*?)\s*\[(\d+)\]/;
  const match = regex.exec(name);
  if (match) {
    return {
      sponsoredBy: parseInt(match[1], 10),
      name: match[2],
      tournamentToplist: parseInt(match[3], 10),
    };
  } else {
    return {
      sponsoredBy: null,
      name: sanitizeTournamentName(name),
      tournamentToplist: null,
    };
  }
};

export const generateTournamentSlug = (tournamentId?: number, name?: string): string => {
  if (!tournamentId || !name) return "tournament";
  const sanitizedName = sanitizeTournamentName(name);
  const slug = sanitizedName.toLowerCase().replace(/\s+/g, "-");
  return `${tournamentId}-${slug}`;
};
