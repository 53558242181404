<script lang="ts">
  import Icons from "$lib/main/components/Icons.svelte";
  import Translate from "../../helpers/Translate.svelte";
</script>

<div class="empty-leaderboard">
  <h3><Translate text="Be the first to compete!" /></h3>
  <p><Translate text="Your points will appear here after you run out of spins. Updated every five minutes." /></p>
</div>

<style>
  .empty-leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    background: #3f3f3f;
    border-radius: 8px;
    color: #fff;
  }

  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  p {
    margin: 0;
    margin-bottom: 1rem;
    color: #ccc;
    font-size: 0.9rem;
  }
</style>
