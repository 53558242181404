<script>
  import { orgCasinoGames, orgGameProviders } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import FocusPanel from "$lib/admin/components/blocks/blocks/FocusPanel.svelte";
  import Table from "$lib/admin/components/blocks/blocks/Table.svelte";
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";

  export let props = null;
  export let adminView = false;

  let topRTPtableData;

  $: {
    try {
      if (
        props?.fixedDataGameProvider &&
        (props.fixedDataGameProvider > 0 ||
          props.fixedDataGameProvider === "-1")
      ) {
        topRTPtableData = $orgCasinoGames?.reduce((acc, obj, index) => {
          try {
            if (
              obj.data?.rtp &&
              !isNaN(obj.data?.rtp) &&
              obj.content.length > 0 &&
              (obj.type === "slots" ||
                obj.type === "videoslots" ||
                obj.type === "videoslot") &&
              (props.fixedDataGameProvider === "-1" ||
                obj.game_provider?.id === props.fixedDataGameProvider)
            ) {
              acc.push(obj);
            }
            return acc;
          } catch (error) {
            console.error(`Error processing record ${index}:`, obj);
            throw error;
          }
        }, []);

        topRTPtableData = topRTPtableData
          .sort(
            (a, b) =>
              Number(b.data?.rtp) - Number(a.data?.rtp) ||
              a.name.localeCompare(b.name)
          )
          .slice(0, props.fixedDataLength ?? 5)
          .map((game) => [
            `<a href='/slots/${game.slug}' title='${game.name}'>${game.name}</a>`,
            `${game.data?.rtp}%`,
          ]);
      } else {
        topRTPtableData = [];
      }
    } catch (error) {
      console.error("Error processing:", error);
    }
  }
</script>

{#if adminView}
  <Group addClass="form-item">
    <Group colspan="4-8">
      <Input
        label="Facts data"
        type="select"
        bind:value={props.fixedDataType}
        on:change
      >
        <option value="fp-bss">Bonus Spins Spins - Focus Panel</option>
        <option value="fp-rrn">Reviews Reviews News - Focus Panel</option>
        <option value="table-rtp">Highest RTP slots - Table</option>
      </Input>
      {#if props.fixedDataType === "table-rtp"}
        <Input
          label="Game provider"
          type="select"
          bind:value={props.fixedDataGameProvider}
          on:change
        >
          <option value="-1">All</option>
          {#each $orgGameProviders as provider}
            <option value={provider.id}>{provider.name}</option>
          {/each}
        </Input>
        <Input
          label="Table length"
          id="length"
          type="number"
          min="1"
          max="10"
          on:keyup
          bind:value={props.fixedDataLength}
        />
      {/if}
    </Group>
  </Group>
{:else if props?.fixedDataType?.startsWith("fp-")}
  {#if props.fixedDataType.includes("bss")}
    <FocusPanel
      props={{
        focusPanelType: "facts",
        focusPanelFacts: [
          {
            number: "70 500 kr",
            description: "casinobonus",
          },
          {
            number: "2 772",
            description: "free spins",
          },
          {
            number: "0",
            description: "free spins utan innsättning",
          },
        ],
      }}
    />
  {:else if props.fixedDataType.includes("rrn")}
    {#if props.data}
      <FocusPanel
        props={{
          focusPanelType: "facts",
          focusPanelFacts: [
            {
              number: "3 456",
              description: "licensierade casinon",
            },
            {
              number: "2 345",
              description: "slots recenserade",
            },
            {
              number: "1 234",
              description: "casinonyheter publicerade",
            },
          ],
        }}
      />
    {/if}
  {/if}
{:else if props?.fixedDataType?.startsWith("table-")}
  {#if topRTPtableData && topRTPtableData.length > 0}
    <Heading {props} />
    <Table
      props={{
        tableColumns: 2,
        tableHeaders: ["Slot", "RTP"],
        tableContent: topRTPtableData,
      }}
    />
  {/if}
{/if}
