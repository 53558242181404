<svelte:options tag="loading-skeleton" />

<script>
  export let width = "100%";
  export let maxWidth = "100%";
  export let height = "10px";
  export let borderRadius = "8px";
  export let baseColor = "rgb(235, 236, 239)";
  export let highlightColor = "rgb(245, 245, 247)";
  export let animationLength = "2s";
  export let customStyles = "";
</script>

<div
  class="skeleton"
  style="height: {height}; width: {width}; max-width: {maxWidth}; border-radius: {borderRadius}; --baseColor: {baseColor}; --highlightColor: {highlightColor}; --animationLength: {animationLength}; {customStyles}"
/>

<style>
  .skeleton {
    background-color: var(--baseColor);
    background-image: linear-gradient(
      90deg,
      var(--baseColor),
      var(--highlightColor),
      var(--baseColor)
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    display: flex;
    width: 100%;
    animation: loading-animation var(--animationLength) ease-in-out infinite;
  }

  @keyframes loading-animation {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
</style>
