<script>
  import { project, orgCasinoGames, orgGameProviders } from '$lib/services/store';
  import { translations } from "$lib/data/translations";
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Icons from "$lib/main/components/Icons.svelte";
  import { createEventDispatcher } from "svelte";

  export let props = null;
  export let adminView = false;

	let selectedTab = "facts";
  let casinoGame = $orgCasinoGames?.find(x => x.id === props.casinoGameId);
  let gameProvider = null;
  let casinoGamesGameProvider = null;
  let avgRTP = null;
  let highestRTP = null;

  const dispatch = createEventDispatcher();

  // TODO: this needs to be refactored as it is running on ANY change to props..
  $: {
    if (props.factsAbout == "casino-game" && props.casinoGameId) {
      props.gameProviderId = ""
      casinoGame = $orgCasinoGames?.find(x => x.id === props.casinoGameId)
    }
  }

  $: {
    if (props.factsAbout == "game-provider" && props.gameProviderId) {
      props.casinoGameId = ""
      gameProvider = $orgGameProviders
      ?.find(x => x.id === props.gameProviderId)
      casinoGamesGameProvider = $orgCasinoGames
      ?.filter(x =>
        x.game_provider?.id === props.gameProviderId)
      if (!casinoGamesGameProvider?.length) {
        avgRTP = "-"
        highestRTP = "-"
      } else {
        // calculate the average RTP for the games at current game provider
        let rtpCount = 0
        avgRTP = (casinoGamesGameProvider?.reduce((accumulator, object) => {
          if (object.data && object.data.rtp && Number(object.data.rtp) && Number(object.data.rtp) > 0) {
            rtpCount += 1
            return accumulator + Number(object.data?.rtp)
          } else {
            return accumulator
          }
        }, 0) / rtpCount).toFixed(2).toLocaleString()

        // calculate the average RTP for the games at current game provider
        highestRTP = casinoGamesGameProvider?.reduce((max, game) => {
          if (game.data.rtp && Number(game.data.rtp) && Number(game.data.rtp) > 0 && game.content.length > 0) {
            return Number(max.data?.rtp) > Number(game.data?.rtp) ? max : game
          } else {
            return max
          }
        })
      }
    }
  }

  // TODO: relativize and merge with orgOperators in IntroReview
  // TODO: create an if based on type of game to show different data
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Facts about"
      type="select"
      bind:value={props.factsAbout}
      on:change={() => dispatch("keyup")}>
      <option value="casino-game">Casino game</option>
      <option value="game-provider">Game provider</option>
    </Input>
  </Group>
  {#if props.factsAbout == "game-provider"}
    <Group colspan="4-8" addClass="form-item">
      <Input
        label="Game provider"
        type="select"
        bind:value={props.gameProviderId}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        {#each $orgGameProviders as gameProvider}
          <option value={gameProvider?.id}>{gameProvider?.name || "Couldn't find casino game name"}</option>
        {/each}
      </Input>
    </Group>
  {:else}
    <Group colspan="4-8" addClass="form-item">
      <Input
        label="Casino game"
        type="select"
        bind:value={props.casinoGameId}
        on:change={() => dispatch("keyup")}>
        <option value="">~not set~</option>
        {#each $orgCasinoGames as casinoGame}
          <option value={casinoGame.id}>{casinoGame.name || "Couldn't find casino game name"}</option>
        {/each}
      </Input>
    </Group>
  {/if}
{:else}
  <Block
    blockClass="block block-tabbed-tables"
    gridColumn="g-col-12"
    {props}>
    {#if props.factsAbout == "game-provider" && props.gameProviderId}
      <div class="tab-section">
        <ul class="tabs">
          <li class:active={selectedTab === "facts"}>
            <button class="icon"><Icons name="info" strokeWidth="2" /> <Translate text="Facts about" /> {gameProvider?.name}</button>
          </li>
        </ul>

        <table class:show={selectedTab === "facts"}>
          <tbody>
              <tr>
                <td><Translate text="Website" /></td>
                <td><a href={`https://${gameProvider?.website}`} targe="_blank" rel="nofollow">{gameProvider?.website}</a></td>
              </tr>
              <tr>
                <td><Translate text="Founded" /></td>
                <td>{gameProvider?.founded}</td>
              </tr>
              <tr>
                <td>Slots</td>
                <td><a href={`#${translations["allgames"][$project.settings.lang]}`}>{casinoGamesGameProvider?.length ?? 0}</a></td>
              </tr>
              {#if avgRTP && avgRTP != "-"}
                <tr>
                  <td>Average RTP</td>
                  <td>{avgRTP + "%" }</td>
                </tr>
              {/if}
              {#if highestRTP?.content && highestRTP?.content?.length}
                <tr>
                  <td>Highest RTP</td>
                  <td><a href={highestRTP?.content?.[0]?.slug}>{highestRTP?.name}</a> {`(${highestRTP?.data?.rtp}%)`}</td>
                </tr>
              {/if}
          </tbody>
        </table>
      </div>
    {:else}
      <div class="tab-section">
        <ul class="tabs">
          <li class:active={selectedTab === "facts"}>
            <button
              class="icon"
              on:click={() => {
                selectedTab = "facts";
              }}><Icons name="info" strokeWidth="2" /> <Translate text="Facts about" /> {casinoGame?.name}</button>
          </li>
          <li class:active={selectedTab === "info"}>
            <button
              class="icon"
              on:click={() => {
                selectedTab = "info";
              }}><Icons name="more-horizontal" strokeWidth="2" /> <Translate text="More info" /></button>
          </li>
        </ul>

        <table class:show={selectedTab === "facts"}>
          <tbody>
              <tr>
                <td><Translate text="Game provider" /></td>
                <td>
                  {#if casinoGame?.game_provider?.content && casinoGame?.game_provider?.name}
                    <a href={casinoGame?.game_provider?.content?.[0].slug}>{casinoGame?.game_provider?.name}</a>
                  {:else}
                    {casinoGame?.software}
                  {/if}
                </td>
              </tr>
              <tr>
                <td><Translate text="Game type" /></td>
                <td>{casinoGame?.type ?? "-"}</td>
              </tr>
              <tr>
                <td>RTP</td>
                <td>{casinoGame?.data?.rtp ?? "-"} %</td>
              </tr>
              <tr>
                <td><Translate text="Release date" /></td>
                <td>{casinoGame?.release_date ?? "-"}</td>
              </tr>
            </tbody>
          </table>
          <table class:show={selectedTab === "info"}>
            <tbody>
              <tr>
                <td><Translate text="Paylines" /></td>
                <td>{casinoGame?.data?.paylines ?? "-"}</td>
              </tr>
              <tr>
                <td><Translate text="Reels" /></td>
                <td>{casinoGame?.data?.reels ?? "-"}</td>
              </tr>
              <tr>
                <td><Translate text="Min. coin per line" /></td>
                <td>{casinoGame?.data?.bets?.minCoinLine ?? "-"}</td>
              </tr>
              <tr>
                <td><Translate text="Max. coin per line" /></td>
                <td>{casinoGame?.data?.bets?.maxCoinLine ?? "-"}</td>
              </tr>
              <tr>
                <td><Translate text="Min. coin value" /></td>
                <td>{casinoGame?.data?.bets?.minCoinValue ?? "-"}</td>
              </tr>
              <tr>
                <td><Translate text="Max. coin value" /></td>
                <td>{casinoGame?.data?.bets?.maxCoinValue ?? "-"}</td>
              </tr>
              <tr>
                <td><Translate text="Jackpot" /></td>
                <td>{casinoGame?.data?.jackpot ?? "-"}</td>
              </tr>
              <tr>
                <td><Translate text="Bonus game" /></td>
                <td>{casinoGame?.data?.features.includes("Bonus game")
                  ? translations["Yes"][$project.settings.lang]
                  : translations["No2"][$project.settings.lang]}</td>
              </tr>
              <tr>
                <td><Translate text="Progressive" /></td>
                <td>{casinoGame?.data?.features.includes("Progressive")
                  ? translations["Yes"][$project.settings.lang]
                  : translations["No2"][$project.settings.lang]}</td>
              </tr>
              <tr>
                <td><Translate text="Wilds" /></td>
                <td>{casinoGame?.data?.features.includes("Wilds")
                  ? translations["Yes"][$project.settings.lang]
                  : translations["No2"][$project.settings.lang]}</td>
              </tr>
              <tr>
                <td><Translate text="Scatter" /></td>
                <td>{casinoGame?.data?.features.includes("Scatter")
                  ? translations["Yes"][$project.settings.lang]
                  : translations["No2"][$project.settings.lang]}</td>
              </tr>
              <tr>
                <td><Translate text="Autoplay" /></td>
                <td>{casinoGame?.data?.features.includes("Autoplay")
                  ? translations["Yes"][$project.settings.lang]
                  : translations["No2"][$project.settings.lang]}</td>
              </tr>
              <tr>
                <td><Translate text="Multiplier" /></td>
                <td>{casinoGame?.data?.features.includes("Multiplier")
                  ? translations["Yes"][$project.settings.lang]
                  : translations["No2"][$project.settings.lang]}</td>
              </tr>
              <tr>
                <td><Translate text="Freespins" /></td>
                <td>{casinoGame?.data?.features.includes("Freespins")
                  ? translations["Yes"][$project.settings.lang]
                  : translations["No2"][$project.settings.lang]}</td>
              </tr>
          </tbody>
        </table>
      </div>
    {/if}
  </Block>
{/if}