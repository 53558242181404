<script>
  import { project } from "$lib/services/store";
  import { createEventDispatcher } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import ImageUpload from "$lib/admin/components/ImageUpload.svelte";
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import ButtonFE from "$lib/admin/components/blocks/helpers/Button.svelte";
  import { ImageFolderEnum } from "$lib/common/enums/imageFolderOptions.ts";

  export let props = null;
  export let adminView = false;

  const dispatch = createEventDispatcher();

  let selectedStep = 0;

  $: {
    if (props.steps === undefined) {
      props.steps = [{}];
    }
  }
</script>

{#if adminView}
  {#if props.steps.length}
    {#each props.steps as step, idx}
      {#if idx === selectedStep}
        <Group colspan="2" addClass="form-item image-editor">
          <ImageUpload
            folder={ImageFolderEnum.MEDIA}
            imageUrl={step.imageUrl}
            on:change={() => dispatch("keyup")}
            on:upload={(event) => {
               const { updatedImageUrl } = event.detail;
               step.imageUrl = updatedImageUrl;
            }}
          />
          {#if step.imageUrl}
            <Group colspan="1" addClass="small-input">
              <Group>
                <Input
                  label="Alt text"
                  placeholder="Alternative text"
                  id="alt-text"
                  bind:value={step.imageAlt}
                  on:keyup
                />
                <Input
                  label="Step title"
                  bind:value={step.title}
                  on:change={() => dispatch("keyup")}
                />
              </Group>
            </Group>
          {/if}
        </Group>
        <Input
          label="Step description"
          bind:value={step.description}
          on:change={() => dispatch("keyup")}
        />
        <Group colspan="1-auto" addClass="form-item narrow">
          <Group colspan="1-auto-auto" addClass="narrow">
            <div class="multi-meta">
              Showing step {selectedStep + 1} of {props.steps.length} steps
            </div>
            <Button
              on:click={() => {
                if (selectedStep > 0) selectedStep -= 1;
                else selectedStep = props.steps.length - 1;
              }}>‹</Button
            >
            <Button
              on:click={() => {
                selectedStep += 1;
                if (selectedStep >= props.steps.length) selectedStep = 0;
              }}>›</Button
            >
          </Group>
          <Group addClass="inline narrow">
            <Button
              addClass="secondary"
              title="Remove this step"
              onlyIcon
              icon="trash-2"
              on:click={() => {
                props.steps = props.steps.filter((_, i) => i !== idx);
                if (selectedStep > 0) {
                  selectedStep -= 1;
                }
                dispatch("keyup");
              }}>Remove</Button
            >
            <Button
              addClass="primary"
              title="Add a step"
              onlyIcon
              icon="plus-square"
              on:click={() => {
                props.steps.push({});
                dispatch("keyup");
              }}>Add a step</Button
            >
          </Group>
        </Group>
      {/if}
    {/each}
  {/if}
{:else if props.steps.length}
  <Block blockClass="block-step-by-step" {props}>
    <Heading {props} />

    <div class="step-by-step-holder">
      {#each props.steps as step, i}
        <div class="step">
          <img
            loading="lazy"
            alt={step.imageAlt}
            class="lazyload"
            data-src={$project.data?.imageFolders?.media +
              step.imageUrl +
              "?w=600&q=80&auto=format"}
            src={$project.data?.imageFolders?.media +
              step.imageUrl +
              "?w=600&q=80&auto=format"}
          />
          <span>{i + 1}. {step.title ?? "test"}</span>
          <p>{step.description}</p>
        </div>
      {/each}
    </div>

    {#if props.buttonUrl && props.buttonAnchor}
      <ButtonFE {props} />
    {/if}
  </Block>
{/if}
