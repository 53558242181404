<script>
  import { project } from "$lib/services/store";
  import Input from "$lib/admin/components/Input.svelte"
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Heading from "$lib/admin/components/blocks/helpers/Heading.svelte";

  export let props = null;
  export let adminView = false;

  let streamData = [
    {
      "time": "21:00",
      "matchup": "EM Deportivo Binacional - César Vallejo",
      "league": "Liga 1, Peru",
      "sport": "soccer"
    },
    {
      "time": "22:30",
      "matchup": "Universidad Católica (ECU) - Aucas",
      "league": "Liga Pro, Ecuador",
      "sport": "soccer"
    },
    {
      "time": "23:00",
      "matchup": "Londrina-PR - Atlético-GO",
      "league": "Brasileirao Serie B, Brasilien",
      "sport": "soccer"
    }
  ]
</script>

{#if adminView}
  <Input
    label="Sports"
    type="select"
    bind:value={props.sportFilter}>
    <option value="0">All</option>
    <option value="1">Soccer</option>
  </Input>
{:else}
  <Block blockClass="block-stream-table">
    <Heading {props} />
    <div class="stream-table-holder">
      {#each streamData as event, i}
        <div class="event">
          <div class="time">{event.time}</div>
          <span class="match">{event.matchup}</span>
          <span class="league">{event.league}</span>
          <a class="logo" href="/spela/unibet/sport"><img loading="lazy" alt="Unibet Logo" src={`${$project.data.imageFolders.logos}/unibet-tv-logo.gif?h=51&amp;q=80&amp;auto=format`} /></a>
          <a class="cta" href="/spela/unibet/sport">Streama här</a>
        </div>
      {/each}
    </div>
  </Block>
{/if}