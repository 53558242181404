<script lang="ts">
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import ExperienceCategory from "./ExperienceCategory.svelte";
  import Preview from "./Preview.svelte";
  import { project, orgOperators, previewMode } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import { ExperienceEnum } from "$lib/common/enums/experience";
  import {
    PUBLIC_VITE_PROJECT_KEY,
    PUBLIC_RANKING_API,
  } from "$env/static/public";
  import AdminView from "./AdminView.svelte";
  import type {
    ExperienceItem,
    ExperienceProps,
    WithdrawalData,
  } from "@/src/types/components/operators/Experience";

  interface Status {
    closed: boolean;
    hidden: boolean;
  }

  export let props: ExperienceProps;
  export let adminView: boolean;

  const lang = $project.settings.lang;
  let operators = $orgOperators as Operator[];
  let withdrawalData: WithdrawalData[] = [];
  let experienceData: ExperienceItem[] = [];
  let statusData = writable<Status>({ closed: false, hidden: false });
  const translationsUtil = translations as any;
  const loading = writable(true);

  const fetchRankingData = async (
    operatorId: number,
    category: string,
    paymentMethod: string = ""
  ) => {
    let url = `${PUBLIC_RANKING_API}/${PUBLIC_VITE_PROJECT_KEY}/`;

    if (category === ExperienceEnum.WITHDRAWAL_EXPERIENCE) {
      url += `paymentmethod?payment_method=${paymentMethod}&operator_id=${operatorId}`;
    } else {
      url += `operator?operator=${operatorId}&category=${category}`;
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ranking data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  };

  onMount(async () => {
    try {
      loading.set(true);
      const experiencesCategories = Object.values(ExperienceEnum);
      const fetchWithdrawalData = fetchRankingData(
        props.operatorId,
        ExperienceEnum.WITHDRAWAL_EXPERIENCE
      );
      const fetchOtherExperiencesData = experiencesCategories
        .filter((exp) => exp !== ExperienceEnum.WITHDRAWAL_EXPERIENCE)
        .map((exp) => fetchRankingData(props.operatorId, exp));

      const [response, ...otherResponses] = await Promise.all([
        fetchWithdrawalData,
        ...fetchOtherExperiencesData,
      ]);

      withdrawalData = response.methods
        .filter((method: any) => method.rankDetails !== null)
        .map((method: any) => ({
          ...method,
        }));

      experienceData = otherResponses
        .map((data, index) => ({
          key: experiencesCategories[index],
          data: {
            ...data,
          },
        }))
        .filter((experience) => experience.data.rankDetails !== null);

      const status = {
        closed:
          experienceData.some((exp) => exp.data.closed) ||
          withdrawalData.some((wd) => wd.closed),
        hidden:
          experienceData.some((exp) => exp.data.hidden) ||
          withdrawalData.some((wd) => wd.hidden),
      };
      statusData.set(status);
    } catch (error) {
      console.error("Error on component mount:", error);
    } finally {
      loading.set(false);
    }
  });
</script>

{#if adminView}
  <AdminView {operators} {props} />
{:else if $statusData.closed || $statusData.hidden}
  {#if $previewMode}
    <Preview {operators} {props} />
  {/if}
{:else}
  <div class="container">
    <div class="grid">
      <div class="g-col-12">
        <h2>{translationsUtil["Our experience"][lang]}</h2>
        <div class="block-content">
          {#if withdrawalData}
            <ExperienceCategory
              {lang}
              loading={$loading}
              data={withdrawalData}
              type="withdrawal"
            />
          {/if}
          {#if experienceData}
            {#each experienceData as experience}
              <ExperienceCategory
                {lang}
                loading={$loading}
                data={[experience]}
                type="experience"
              />
            {/each}
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .container {
    margin-bottom: 32px;
  }
  .block-content {
    background-color: #f6f6f6;
    border-radius: 10px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    @media only screen and (min-width: 991px) {
      grid-template-columns: 1fr 1fr;
      & > div:nth-child(odd) {
        border-right: 1px solid white;
      }
    }
    & > div {
      border-bottom: 1px solid white;
    }

    @media only screen and (max-width: 991px) {
      & > div:last-child {
        border-bottom: none;
      }
    }
    @media only screen and (min-width: 991px) {
      & > div:nth-last-child(-n + 2) {
        border-bottom: none;
      }
    }
  }
</style>
