<script>
	import Group from "$lib/admin/components/Group.svelte";
	import Input from "$lib/admin/components/Input.svelte";
	import Cards from "$lib/admin/components/Cards.svelte";
	import Button from "$lib/main/components/Button.svelte";
	import { createEventDispatcher } from "svelte";

	// props.text: Button link text
	// props.link: Button link
	// props.termsAndConditions: Array[{text: term/condition string, link: optional link}]
	// props.sticky: bool - attach to bottom of page
	export let props = null;
	export let adminView = false;

	if (props.sticky === undefined) props.sticky = false;
	if (props && !props.termsAndConditions) props.termsAndConditions = [];

	const dispatch = createEventDispatcher();
</script>

{#if adminView}
	<Cards type="form">

		<!-- Button text and link -->
		<Group colspan="2">
			<Group>
				<Input placeholder="Display text" bind:value={props.text} on:keyup />
			</Group>
			<Group>
				<Input placeholder="Link" bind:value={props.link} on:keyup />
			</Group>
			<Group colspan="1-11">
				<Input
					label="Sticky"
					type="checkbox"
					bind:checked={props.sticky}
					on:change
				/>
			</Group>
		</Group>

		<!-- Add terms and conditions 'caption' -->
		<Group colspan="1">
			<table>
				{#each props.termsAndConditions ?? [] as tc, jdx}
					<tr>
						<td>
							<Input
								placeholder="Term / Condition"
								bind:value={tc.text}
								on:keyup
							/>
						</td>
						<td>
							<Input placeholder="Link" bind:value={tc.link} on:keyup />
						</td>
						<td>
							<Button
								addClass="primary"
								on:click={() => {
									props.termsAndConditions = props.termsAndConditions.filter(
										(_, i) => i !== jdx
									);
									dispatch("keyup");
								}}>Remove</Button
							>
						</td>
					</tr>
				{/each}
			</table>
			<Button
				addClass="tertiary"
				on:click={() => {
					props.termsAndConditions.push({ text: "", link: "" });
					dispatch("keyup");
				}}>Add Term / Condition</Button
			>
		</Group>
	</Cards>
{:else if !adminView && props}
	<!-- TODO: Sticky does not appear to anchor to bottom of page -->
	<Cards type="form" addClass={props.sticky ? "sticky" : ""} >
		<Group colspan="1">
			<Button link={props.link} addClass="secondary large">
				{props.text}
			</Button>
			<p class="center">
				{#each props.termsAndConditions ?? [] as tc, idx}
					{#if tc.link}
						<a href={tc.link}>{tc.text}</a>
					{:else}
						{tc.text}
					{/if}
					{idx + 1 < props.termsAndConditions.length ? " | " : ""}
				{/each}
			</p>
		</Group>
	</Cards>
{/if}

<style>
	.center {
		margin: auto;
	}
</style>
