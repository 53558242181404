<script lang="ts">
  import { slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Skeleton from "$lib/main/components/Skeleton.svelte";
  import { formatUnixDate } from "$lib/common/helpers/date";
  import type { Tab } from "@/src/types/components/DataTable";
  import { sanitizeTournamentName } from "$lib/services/tournaments/nameUtils";
  import EmptyLeaderboard from "./EmptyLeaderboard.svelte";
  import Tooltip from "$lib/main/components/Tooltip.svelte";
  import PrizeDisplay from "./PrizeDisplay.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  export let tabs: Tab[];
  export let hideHeader: boolean;
  export let accordionData: Record<number, any[]>;

  function isEmptyLeaderboard(data: any[]): boolean {
    return data.length === 1 && "isEmptyLeaderboard" in data[0] && data[0].isEmptyLeaderboard === true;
  }

  let skeletons = Array(5).fill({});

  const dispatch = createEventDispatcher();

  function toggleAccordion(index: number) {
    tabs[index].isOpen = !tabs[index].isOpen;
    if (tabs[index].isOpen && !accordionData[index]) {
      dispatch("fetchData", index);
    }
    tabs = [...tabs];
  }

  function filterData(row: any, currentTab: Tab) {
    const columns = Array.isArray(currentTab.selectedColumns) ? currentTab.selectedColumns : [];

    for (const col of columns) {
      if (
        col.key in row &&
        typeof row[col.key] === "boolean" &&
        col.booleanValue !== "any" &&
        col.booleanValue !== undefined
      ) {
        if (row[col.key] !== col.booleanValue) {
          return false;
        }
      }
    }
    return true;
  }

  function getEndpointClass(endpoint?: string): string {
    return endpoint ? endpoint.toLowerCase() : "";
  }
</script>

<div class="accordion">
  {#each tabs as tab, index}
    <div class="accordion-item {getEndpointClass(tab.endpoint)}">
      <div
        class="accordion-header"
        role="button"
        tabindex="0"
        on:click={() => toggleAccordion(index)}
        on:keydown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            toggleAccordion(index);
          }
        }}>
        {#if getEndpointClass(tab.endpoint) === "leaderboard"}
          <Icons name="trophy" fill="#FFDD4D" strokeWidth="0" width="20" height="20" />
        {/if}
        <div class="accordion-header-title">
          {tab.title}
          {#if getEndpointClass(tab.endpoint) === "leaderboard"}
            <Tooltip
              content="Once you have no more spins, your score will be added within 5 minutes."
              direction="bottom"
              theme="dark"
              minWidth="200px"
              maxWidth="350px"
              hideOnMobile={true}>
              <Icons strokeWidth="2" color="#ffe684" name="help-circle" />
            </Tooltip>
          {/if}
        </div>
        <div class="icon">
          {#if tab.isOpen}
            <Icons name="chevron-up" strokeWidth="0" fill="#fff" width="20" height="20" />
          {:else}
            <Icons name="chevron-down" strokeWidth="0" fill="#fff" width="20" height="20" />
          {/if}
        </div>
      </div>
      {#if tab.isOpen}
        {#if getEndpointClass(tab.endpoint) === "leaderboard"}
          <div class="leaderboard-update-disclaimer">
            <Icons name="alert-circle" width="24" height="24" color="#ffe684" strokeWidth="2" />
            <Translate text="Once you have no more spins, your score will be added within 5 minutes." />
          </div>
        {/if}
        <div class="accordion-content {getEndpointClass(tab.endpoint)}" transition:slide={{ duration: 300 }}>
          {#if !accordionData[index]}
            <table>
              <tbody>
                {#each skeletons as _, i}
                  <tr>
                    <td colspan={tab.selectedColumns.filter((col) => !col.hidden).length}>
                      <Skeleton
                        maxWidth="100%"
                        height="20px"
                        baseColor="rgb(23, 23, 23)"
                        highlightColor="rgb(38, 38, 38)"
                        customStyles="margin-bottom: 10px;" />
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          {:else if isEmptyLeaderboard(accordionData[index]) || accordionData[index].length === 0}
            <EmptyLeaderboard />
          {:else}
            <table class="table-content {getEndpointClass(tab.endpoint)}">
              {#if !hideHeader}
                <thead>
                  <tr class="row header">
                    {#each tab.selectedColumns.filter((col) => !col.hidden) as column}
                      <th>{column.displayName}</th>
                    {/each}
                  </tr>
                </thead>
              {/if}
              <tbody>
                {#each accordionData[index].filter((row) => filterData(row, tab)) as row}
                  <tr class="row">
                    {#each tab.selectedColumns.filter((col) => !col.hidden) as column}
                      <td>
                        {#if column.key === "displayPrize"}
                          <PrizeDisplay content={row[column.key]} />
                        {:else if column.key === "logoImage" && "backgroundImage" in row}
                          <div class="logo-container">
                            <img class="background-image" src={row.backgroundImage} alt="Background" referrerpolicy="no-referrer"/>
                            <img src={row.logoImage} alt="Logo" class="logo-image" referrerpolicy="no-referrer" />
                          </div>
                        {:else if column.key in row}
                          {#if typeof row[column.key] === "boolean"}
                            {row[column.key] ? "True" : "False"}
                          {:else if column.key === "startDate" || column.key === "endDate"}
                            {formatUnixDate(row[column.key])}
                          {:else if column.key === "name"}
                            {sanitizeTournamentName(row[column.key])}
                          {:else}
                            {row[column.key] !== undefined ? row[column.key] : "N/A"}
                          {/if}
                        {:else}
                          N/A
                        {/if}
                      </td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            </table>
          {/if}
        </div>
      {/if}
    </div>
  {/each}
</div>

<style>
  .accordion {
    width: 100%;
    border-radius: 7px;
    overflow: hidden;
  }

  .accordion-item.leaderboard {
    overflow: visible;
    & .accordion-header {
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
    }
    & .accordion-content {
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 7px;
    }
  }

  .accordion-item {
    border-radius: 7px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #121212;
    color: #fff;
    cursor: pointer;
  }

  .accordion-header-title {
    margin-left: 9px;
  }

  .accordion-header .icon {
    margin-left: auto;
  }

  .accordion-content {
    background-color: #282828;
  }

  .accordion-content .header th {
    background-color: transparent;
  }

  .table-content {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
  }

  .table-content .row th,
  .table-content .row td {
    padding: 4px;
    text-align: left;
    background-color: #3f3f3f;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
  }

  .table-content .row th {
    background-color: transparent;
  }

  .leaderboard-update-disclaimer {
    padding: 5px 8px;
    background: #3f3f3f;
    font-size: 13px;
    font-weight: 300;
    text-align: left;
    display: flex;
    line-height: 18px;
    gap: 8px;
    align-items: center;
    transition: all 150ms ease-in-out;
    @media only screen and (min-width: 991px) {
      display: none;
    }
    & button {
      background-color: transparent;
      border: none;
    }
  }

  .table-content.leaderboard tbody .row:nth-child(1) {
    background: linear-gradient(90deg, rgba(255, 207, 58, 0.7) 0%, rgba(153, 124, 35, 0.7) 100%);
    & td {
      background-color: transparent;
    }
  }

  .table-content.leaderboard tbody .row:nth-child(2) {
    background: linear-gradient(90deg, rgba(209, 209, 209, 0.7) 0%, rgba(107, 107, 107, 0.7) 100%);
    & td {
      background-color: transparent;
    }
  }

  .table-content.leaderboard tbody .row:nth-child(3) {
    background: linear-gradient(90deg, rgba(216, 117, 79, 0.7) 0%, rgba(114, 62, 42, 0.7) 100%);
    & td {
      background-color: transparent;
    }
  }

  .logo-container {
    position: relative;
    width: 170px;
    height: 62px;
    overflow: hidden;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 95%;
    object-fit: contain;
    z-index: 2;
  }
</style>
