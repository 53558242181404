<script lang="ts">
  import { getOrdinal } from "$lib/common/helpers/ordinalFormat";
  import Icons from "$lib/main/components/Icons.svelte";
  import RatingBar from "./RatingBar.svelte";
  import { translate } from "$lib/common/helpers/translate";
  import Modal from "$lib/main/components/Modal.svelte";
  import OperatorItem from "$lib/admin/components/blocks/helpers/OperatorItem.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import { writable } from "svelte/store";
  import { onMount } from "svelte";
  import Skeleton from "$lib/main/components/Skeleton.svelte";
  import { truncateText } from "$lib/common/helpers/truncateText";
  import Translate from "../../helpers/Translate.svelte";
  import { getBucketLink } from "$lib/common/helpers/image";

  export let lang: string = "";
  export let title: string;
  export let ratingValue: number;
  export let totalScore: number;
  export let rank: number | undefined = undefined;
  export let summary: string = "";
  export let rankingList: Array<any> = [];
  export let totalOperators: number = 0;
  export let medianSectionScore: number = 0;
  export let overallScore: boolean = false;
  export let loading: boolean = false;
  export let operatorName: string = "";
  export let operatorVisuals: any = "";

  let showFullText = false;
  let toggleModal = false;
  let searchText: string = "";
  let filteredList = rankingList;
  const fullWidth = writable(false);

  function toggleText() {
    showFullText = !showFullText;
  }

  function openModal() {
    filteredList = rankingList;
    toggleModal = true;
  }

  const updateFullWidth = () => {
    fullWidth.set(window.innerWidth < 991);
  };

  function filterList() {
    if (!searchText) {
      filteredList = rankingList;
      return;
    }

    filteredList = rankingList.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  onMount(() => {
    updateFullWidth();
    window.addEventListener("resize", updateFullWidth);

    return () => {
      window.removeEventListener("resize", updateFullWidth);
    };
  });

  $: displayedSummary =
    showFullText || !summary ? summary : truncateText(summary, 16);
  $: isExpandable = summary.split(" ").length > 16;
</script>

<div class={overallScore ? "category-overall-score" : "category"}>
  {#if title && !overallScore}
    {#if loading}
      <Skeleton
        maxWidth="150px"
        height="11px"
        customStyles="margin-top: 8px; margin-bottom: 7px;"
      />
    {:else}
      <div class="title">{title}</div>
    {/if}
  {/if}
  <div class="ranking">
    {#if loading}
      {#if overallScore}
        <Skeleton
          width="212px"
          height="10px"
          customStyles="margin-top: 10px; margin-bottom: 9px;"
        />
      {:else}
        <Skeleton
          height="11px"
          width="70px"
          customStyles="margin-top: 8px; margin-bottom: 7px;"
        />
      {/if}
    {:else if rank}
      {getOrdinal(rank, lang)}
      {#if overallScore}
        <Translate text="rank of" />
        {totalOperators}
        <Translate text="casinos" />
      {/if}
      <button
        data-element="ratings"
        data-operator={operatorName}
        data-type={title}
        data-compare-pos={rank}
        class="button-toggle modal-opener"
        on:click={openModal}
      >
        <Icons name="help-circle" strokeWidth="2" width="16" height="16" />
      </button>
    {/if}
  </div>
  <div class="bar">
    <RatingBar {totalScore} score={ratingValue} {loading} />
  </div>
  {#if summary}
    <div class="summary">
      {#if loading}
        <Skeleton maxWidth="400px" customStyles="margin-top: 7px;" />
        <Skeleton maxWidth="300px" customStyles="margin-top: 18px;" />
        <Skeleton
          maxWidth="100px"
          customStyles="margin-top: 7px; margin-bottom: 5px;"
        />
      {:else}
        {displayedSummary}
        {#if isExpandable}
          <button class="button-toggle truncate" on:click={toggleText}>
            {showFullText
              ? translate("Show less", lang) + "..."
              : translate("Show more", lang) + "..."}
          </button>
        {/if}
      {/if}
    </div>
  {/if}
</div>

{#if toggleModal}
  <Modal bind:toggleModal bind:fullWidth={$fullWidth} removeHeader={true}>
    <div class="container operator-modal">
      <div class="head">
        <Button
          variant="text"
          type="button"
          addClass="text-btn"
          on:click={() => (toggleModal = false)}
        >
          <Icons name="arrow-left"></Icons>
        </Button>

        <h3>
          {#if overallScore}
            {operatorName + "s"}
            {translate("overall rating title", lang)}
          {:else}
            {translate(title, lang)}
          {/if}
        </h3>

        <div class="empty"></div>
      </div>
      <div
        class="selected-operator-modal"
        style={operatorVisuals?.primaryColor === "255,255,255,1"
          ? ""
          : `background-color: rgba(${operatorVisuals?.primaryColor || "0,0,0,1"}); color: rgba(${operatorVisuals?.textColor || "255,255,255,1"})`}
      >
        <div class="logo">
          <img
            alt={operatorName}
            src="{getBucketLink()}operators/{operatorVisuals.logo}"
          />
          <div
            style="width: 100%; text-align: center; font-size: 18px; margin-top: 10px;"
          >
            #{rank}
          </div>
        </div>
        <div class="rank-info">
          <div
            class="rank-box"
            style={operatorVisuals?.primaryColor === "255,255,255,1"
              ? "background-color: rgba(0,0,0,0.1);"
              : ""}
          >
            <Icons
              stroke="rgba({operatorVisuals.textColor})"
              strokeWidth="0"
              fill="rgba({operatorVisuals.textColor})"
              name="total-operators"
              width="32"
              height="32"
            />
            <span>{translate("Amount tested casinos", lang)}:</span>
            {totalOperators}
          </div>
          <div
            class="rank-box"
            style={operatorVisuals?.primaryColor === "255,255,255,1"
              ? "background-color: rgba(0,0,0,0.1);"
              : ""}
          >
            <Icons
              stroke="rgba({operatorVisuals.textColor})"
              strokeWidth="0"
              fill="rgba({operatorVisuals.textColor})"
              name="bullseye"
              width="32"
              height="32"
            />
            <span>{operatorName} {translate("avg value", lang)}:</span>
            {ratingValue}
          </div>
          <div
            class="rank-box"
            style={operatorVisuals?.primaryColor === "255,255,255,1"
              ? "background-color: rgba(0,0,0,0.1);"
              : ""}
          >
            <Icons
              stroke="rgba({operatorVisuals.textColor})"
              strokeWidth="0"
              fill="rgba({operatorVisuals.textColor})"
              name="median-score"
              width="32"
              height="32"
            />
            <span>{translate("Median value", lang)}:</span>
            {medianSectionScore}
          </div>
        </div>
      </div>
      <h3 style="text-align: center; margin-top: 50px;">
        {#if overallScore}
          {translate("Comparison List", lang)}
        {:else}
          {translate("Best results", lang)}: {title}
        {/if}
      </h3>
      <input
        class="input-search"
        placeholder={translate("Search", lang)}
        bind:value={searchText}
        on:keyup={filterList}
      />
      <div class="rank-list">
        {#each filteredList as operatorItem}
          <OperatorItem
            title={translate("Ranking List", lang)}
            type="ratings-modal"
            rankingMode={"ratings"}
            {operatorItem}
          ></OperatorItem>
        {/each}
      </div>
    </div>
  </Modal>
{/if}

<style>
  .category {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0fr 0fr 0fr;
    gap: 10px;
    grid-auto-flow: row;
    grid-template-areas:
      "title ranking"
      "bar bar"
      "summary summary";
    font-weight: 300;
  }

  .category-overall-score {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0fr 0fr;
    grid-auto-flow: row;
    grid-template-areas:
      "bar bar"
      "ranking ranking";
    font-weight: 300;
  }

  .title {
    grid-area: title;
    font-weight: 700;
  }

  .ranking {
    justify-self: end;
    grid-area: ranking;
    display: flex;
    gap: 10px;
  }

  .bar {
    grid-area: bar;
  }

  .summary {
    grid-area: summary;
  }
  .button-toggle {
    display: block;
    background: none;
    border: none;
    color: var(--clr-qua);
    cursor: pointer;
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-align: left;
    &.modal-opener {
      display: initial;
      color: initial;
    }
  }
  .button-toggle:focus {
    outline: none;
  }
  .operator-modal {
    padding: 12px 16px;
    margin: 0;
    padding-bottom: 180px;
  }

  @media (min-width: 768px) {
    .operator-modal {
      padding-bottom: 180px;
    }
  }

  .selected-operator-modal {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "logo"
      "rank-info";
    border-radius: 8px;
    background-color: #f4f4f4;
    @media only screen and (min-width: 991px) {
      display: grid;
      grid-template-columns: 300px 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: column;
      grid-template-areas: "logo rank-info rank-info";
    }
  }

  .logo {
    width: 300px;
    grid-area: logo;
    padding-right: 10px;
    @media only screen and (min-width: 991px) {
      padding-right: 0px;
    }
    & > img {
      display: block;
      margin: auto;
      height: 60px;
    }
  }

  .rank-info {
    padding-right: 10px;
    display: grid;
    height: auto;
    align-self: center;
    font-size: 14px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5px;
    grid-template-areas:
      "."
      "."
      ".";
    grid-area: rank-info;
    @media only screen and (min-width: 991px) {
      padding-right: 0px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: row;
      grid-template-areas:
        ". ."
        ". .";
    }
  }

  .rank-box {
    padding: 5px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    gap: 10px;
  }

  .rank-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .input-search {
    background-color: #f4f4f4;
    padding: 14px 9px;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    border: none;
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .empty {
    width: 32px;
    height: 16px;
  }
</style>
