<script>
  import Input from "$lib/admin/components/Input.svelte";
  import Group from "$lib/admin/components/Group.svelte";

  export let props = null
  export let adminView = false
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Separator type"
      id="separator"
      type="select"
      bind:value={props.separatorClass}
      on:change
      >
      <option value="">Basic</option>
      <option value="thick-colored">Thick colored</option>
      <option value="circle-arrow">Circle arrow</option>
    </Input>
  </Group>
{:else}
  <hr class={props.separatorClass}>
{/if}

<style>
  hr.thick-colored {
    border-bottom: 3px solid var(--clr-pri);
  }
  hr.circle-arrow {
    padding: 4rem 0 0 0;
    margin: 0 0 4rem 0 !important;
    position: relative;
    border: none;
    border-bottom: 3px solid var(--clr-pri);
    overflow: visible;
    line-height: 0;
  }
  hr.circle-arrow:before {
    content: "\203a";
    width: 6rem;
    height: 6rem;
    font-weight: 900;
    color: var(--clr-pri);
    border: 3px solid var(--clr-pri);
    background: var(--bg-clr);
    position: absolute;
    left: calc(50% - 3rem + 1.5px);
    top: calc(1rem + 1.5px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }
  hr.circle-arrow:before {
	  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='%23f49c23' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  }
</style>