<script lang="ts">
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let operators;
  export let props;
</script>

<Group colspan="4-8">
  <Input
    type="select"
    label="Experience list"
    on:change
    bind:value={props.experience}
  >
    <option value="all">All</option>
  </Input>
</Group>
<Group colspan="4-8">
  <Input
    label="Operator"
    type="select"
    bind:value={props.operatorId}
    on:change={() => dispatch("keyup")}
  >
    <option value="">~not set~</option>
    {#each operators as operator}
      <option value={operator.id}
        >{operator.name ?? "Couldn't find metadata"}</option
      >
    {/each}
  </Input>
</Group>
