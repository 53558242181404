<script>
  import { fade } from "svelte/transition";
  import Group from "$lib/admin/components/Group.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import Button from "$lib/main/components/Button.svelte";

  import imports from "$lib/admin/components/blocks/Importer.js";

  export let props = null;
  export let adminView = false;

  function addBlockToContentArray(block) {
    if (!props?.contentArray) {
      props.contentArray = []
    }
    props.contentArray.push(block)
    props = props
  }

  function removeFromArray(index) {
    props.contentArray.splice(index, 1)
    props = props
  }

  function swapBlocks(index1, index2) {
    const temp = props.contentArray[index1];
    props.contentArray[index1] = props.contentArray[index2];
    props.contentArray[index2] = temp;
    props = props;
  }
</script>

{#if adminView}
  <Group addClass="form-item">
    {#if props.contentArray}
      {#each props.contentArray as block, index}
        <div class="focus-panel-block" transition:fade>
          <div class="focus-panel-block-item">
            <div class="block-item-header">
              <span>{`${index + 1}. ${block.blockType}`}</span>
              <Group colspan="3" addClass="narrow inline">
                <Button
                  title="Move this block one step up"
                  icon="chevron-up" onlyIcon iconSize="12" iconStroke="1.5"
                  disabled={ index == 0 }
                  on:click={() => swapBlocks(index, index - 1)} />
                <Button
                  title="Move this block one step down"
                  icon="chevron-down" onlyIcon iconSize="12" iconStroke="1.5"
                  disabled={ index == props.contentArray.length - 1 }
                  on:click={() => swapBlocks(index, index + 1)} />
                <Button addClass="secondary" onlyIcon icon="trash-2" on:click={() => removeFromArray(index)} />
              </Group>
            </div>
            <div class="block-item-main">
              <svelte:component
                this={imports[block.blockType].cls}
                bind:props={block.props}
                {adminView} />
            </div>
          </div>
        </div>
      {/each}
    {/if}
    <Group colspan="4" addClass="button-group">
      <Button
        icon="plus-square"
        on:click={() => {
        addBlockToContentArray({
          "blockType": "Content",
          "props": {}
        })
        }}>Add Content</Button>
      <Button
        icon="plus-square"
        on:click={() => {
        addBlockToContentArray({
          "blockType": "List",
          "props": {}
        })
        }}>Add List</Button>
      <Button
        icon="plus-square"
        on:click={() => {
        addBlockToContentArray({
          "blockType": "Image",
          "props": {}
        })
        }}>Add Image</Button>
      <Button
        icon="plus-square"
        on:click={() => {
        addBlockToContentArray({
          "blockType": "Links",
          "props": {}
        })
        }}>Add Button</Button>
    </Group>
  </Group>
{:else}
  <Block
    blockClass="block block-focus-panel block-focus-panel-content"
    gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
    {props}>
    <div class="focus-panel content">
      {#if props.contentArray}
        {#each props.contentArray as block, i}
          <svelte:component
            this={imports[block.blockType].cls}
            bind:props={block.props}
            adminView={false} />
        {/each}
      {/if}
    </div>
  </Block>
{/if}