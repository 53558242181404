<script>
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Links from "$lib/admin/components/blocks/blocks/Links.svelte";

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      bind:value={props.header}
      label="Heading"
      placeholder="Write an H1"
      on:keyup
    />
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      bind:value={props.subHeader}
      label="Sub heading"
      placeholder="Write a sub-header"
      on:keyup
    />
  </Group>
  <Links {props} {adminView} />
{:else}
  <Block blockClass="block-intro-links">
    <h1>{props.header ? props.header : ""}</h1>
    {#if props.subHeader}
      <span>{props.subHeader}</span>
    {/if}
    {#if props.links}
      <div class="link-group">
        {#each props.links as link}
          <a href={link.url}>{link.anchor}</a>
        {/each}
      </div>
    {/if}
  </Block>
{/if}