<script lang="ts">
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  export let content: string;
  export let direction: "top" | "bottom" | "left" | "right" = "top";
  export let theme: "dark" | "light" | "error" | "success" | "warning" = "dark";
  export let delay: number = 200;
  export let minWidth: string = "200px";
  export let maxWidth: string | undefined = undefined;
  export let hideOnMobile: boolean = false;

  let tooltip: HTMLDivElement;
  let isVisible = false;
  let timeoutId: NodeJS.Timeout;
  let tooltipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;

  const showTooltip = () => {
    timeoutId = setTimeout(() => {
      isVisible = true;
    }, delay);
  };

  const hideTooltip = () => {
    clearTimeout(timeoutId);
    isVisible = false;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && isVisible) {
      hideTooltip();
    }
  };

  $: themeClass = {
    dark: "dark-theme",
    light: "light-theme",
    error: "error-theme",
    success: "success-theme",
    warning: "warning-theme",
  }[theme];

  $: directionClass = {
    top: "tooltip-top",
    bottom: "tooltip-bottom",
    left: "tooltip-left",
    right: "tooltip-right",
  }[direction];

  $: tooltipStyle = [`--min-tooltip-width: ${minWidth}`, maxWidth && `--max-tooltip-width: ${maxWidth}`]
    .filter(Boolean)
    .join(";");
</script>

<div
  class="tooltip-container {hideOnMobile ? 'hide-on-mobile' : ''}"
  role="button"
  tabindex="0"
  aria-describedby={isVisible ? tooltipId : undefined}
  on:mouseenter={showTooltip}
  on:mouseleave={hideTooltip}
  on:focus={showTooltip}
  on:blur={hideTooltip}
  on:keydown={handleKeyDown}>
  <div class="tooltip-trigger">
    <slot />
  </div>

  {#if isVisible}
    <div
      bind:this={tooltip}
      id={tooltipId}
      class="tooltip {themeClass} {directionClass}"
      role="tooltip"
      style={tooltipStyle}>
      <div class="tooltip-content">
        <Translate text={content} />
      </div>
      <div class="tooltip-arrow" aria-hidden="true" />
    </div>
  {/if}
</div>

<style>
  .tooltip-container {
    position: relative;
    display: inline-block;
  }

  @media only screen and (max-width: 990px) {
    .tooltip-container.hide-on-mobile {
      display: none;
    }
  }

  .tooltip-container:focus {
    outline: none;
  }

  .tooltip-container:focus-visible {
    outline: 2px solid #4c9ffe;
    outline-offset: 2px;
    border-radius: 2px;
  }

  .tooltip-trigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .tooltip {
    position: absolute;
    z-index: 50;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 4px;
    pointer-events: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    min-width: var(--min-tooltip-width);
    max-width: var(--max-tooltip-width, calc(100vw - 2rem));
    width: max-content;
  }

  .tooltip-content {
    text-align: center;
    white-space: normal;
    word-break: normal;
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid transparent;
  }

  .tooltip-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-8px);
  }

  .tooltip-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(8px);
  }

  .tooltip-left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(-8px);
  }

  .tooltip-right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(8px);
  }

  .tooltip-top .tooltip-arrow {
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: inherit;
  }

  .tooltip-bottom .tooltip-arrow {
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-color: inherit;
  }

  .tooltip-left .tooltip-arrow {
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-left-color: inherit;
  }

  .tooltip-right .tooltip-arrow {
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-right-color: inherit;
  }

  /* themes */
  .dark-theme {
    background-color: #121212;
    color: #ffffff;
    border: 1px solid #575757;
  }

  .light-theme {
    background-color: #ffffff;
    color: #121212;
    border: 1px solid #e2e8f0;
    border-color: #ffffff;
  }

  .error-theme {
    background-color: #ef4444;
    color: #ffffff;
    border-color: #ef4444;
  }

  .success-theme {
    background-color: #22c55e;
    color: #ffffff;
    border-color: #22c55e;
  }

  .warning-theme {
    background-color: #f59e0b;
    color: #121212;
    border-color: #f59e0b;
  }
</style>
