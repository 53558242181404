import {
  PUBLIC_SOCIAL_TOURNAMENT_URL,
  PUBLIC_SOCIAL_TOURNAMENT_LICENSE,
  PUBLIC_SOCIAL_TOURNAMENT_API_KEY,
} from "$env/static/public";
import { SignJWT } from "jose";
import type {
  Tournament,
  LeaderboardResponse,
  PlayerResultsResponse,
  SessionDataResponse,
  VerifyResultResponse,
} from "@/src/types/tournaments";

const getJWTToken = async (): Promise<string> => {
  const secretKey = new TextEncoder().encode(PUBLIC_SOCIAL_TOURNAMENT_API_KEY);
  const jwt = await new SignJWT({
    exp: Math.floor(Date.now() / 1000) + 60 * 60,
  })
    .setProtectedHeader({ alg: "HS256" })
    .sign(secretKey);
  return jwt;
};

const customFetch = async (
  url: string,
  method: string,
  body: any = null,
  additionalHeaders: Record<string, string> = {},
) => {
  const token = await getJWTToken();
  const headers = {
    "Content-Type": "application/json",
    license: PUBLIC_SOCIAL_TOURNAMENT_LICENSE,
    Authorization: `${token}`,
    ...additionalHeaders,
  };

  const options = { method, headers, body: body ? JSON.stringify(body) : null };

  const response = await fetch(url, options);

  if (!response.ok) {
    const errorText = await response.text();
    console.error(`Error fetching ${url}: ${response.status} - ${errorText}`);
    throw new Error(`Failed to fetch data from ${url}. Status: ${response.status}`);
  }

  if (response.status === 204) {
    return { success: true };
  }

  try {
    const json = await response.json();
    return json;
  } catch (err) {
    console.error(`Error parsing JSON from ${url}:`, err);
    throw new Error(`Invalid JSON response from ${url}`);
  }
};

const encodeQueryParams = (params: Record<string, any>): string => {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join("&");
};

export const fetchTournaments = async (): Promise<Tournament[]> => {
  const response = await customFetch(PUBLIC_SOCIAL_TOURNAMENT_URL + "/getTournamentFullSchedule", "GET");
  return response.data;
};

export const fetchLiveTournaments = async (username?: string): Promise<Tournament[]> => {
  const headers = {
    "Content-Type": "application/json",
    license: PUBLIC_SOCIAL_TOURNAMENT_LICENSE,
    Authorization: await getJWTToken(),
    ...(username && { username }),
  };
  const response = await customFetch(PUBLIC_SOCIAL_TOURNAMENT_URL + "/getLiveTournaments", "GET", null, headers);
  return response.data;
};

export const fetchTournamentResults = async (
  page: number = 1,
  username?: string,
): Promise<{
  tournaments: Tournament[];
  maxPages: number;
  currentPage: number;
}> => {
  const headers = {
    "Content-Type": "application/json",
    license: PUBLIC_SOCIAL_TOURNAMENT_LICENSE,
    Authorization: await getJWTToken(),
    ...(username && { username }),
  };

  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getTournamentResults?page=${page}`,
    "GET",
    null,
    headers,
  );

  return {
    tournaments: response.data,
    maxPages: response.maxPages,
    currentPage: response.currentPage,
  };
};

export const fetchAllFinishedTournaments = async (limit: number = 10): Promise<Tournament[]> => {
  try {
    const finishedTournaments: Tournament[] = [];
    let currentPage = 1;
    while (finishedTournaments.length < limit) {
      const { tournaments, maxPages } = await fetchTournamentResults(currentPage);
      const finishedFromPage = tournaments.filter((tournament) => tournament.scheduleTimerTitle === "finished");
      finishedTournaments.push(...finishedFromPage.slice(0, limit - finishedTournaments.length));
      if (finishedTournaments.length >= limit || currentPage >= maxPages) {
        break;
      }
      currentPage++;
    }

    return finishedTournaments;
  } catch (error) {
    console.error("Error fetching finished tournaments:", error);
    throw error;
  }
};

export const fetchTournamentLeaderBoard = async (tournament_id: number): Promise<LeaderboardResponse> => {
  const queryParams = encodeQueryParams({ tournament_id });
  const response = await customFetch(`${PUBLIC_SOCIAL_TOURNAMENT_URL}/getTournamentLeaderBoard?${queryParams}`, "GET");
  return response.data.scoreboard;
};

export const fetchFeatureTournaments = async (tournamentIdList: number): Promise<Tournament[]> => {
  const queryParams = encodeQueryParams({ tournamentIdList });
  const response = await customFetch(`${PUBLIC_SOCIAL_TOURNAMENT_URL}/getFeatureTournaments?${queryParams}`, "GET");
  return response.data;
};

export const fetchPlayerTournamentHistory = async (username: string): Promise<any> => {
  const queryParams = encodeQueryParams({ username });
  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getPlayerTournamentHistory?${queryParams}`,
    "GET",
    null,
    { username },
  );
  return response.data;
};

export const startTournamentGame = async (tournament_id: number, username: string): Promise<any> => {
  const body = {
    tournament_id,
    action: "join",
  };
  const response = await customFetch(`${PUBLIC_SOCIAL_TOURNAMENT_URL}/startGame`, "POST", body, { username });
  return response;
};

export const registerUser = async (username: string, externalUserId: number, hash: string): Promise<any> => {
  const body = {
    username,
    external_user_id: externalUserId,
    st_partner_name: "casinofeber",
    hash: hash,
  };

  const response = await customFetch(`${PUBLIC_SOCIAL_TOURNAMENT_URL}/registerUser`, "POST", body);
  return response;
};

export const fetchRunningTournament = async (tournamentId: number, username: string): Promise<any> => {
  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getRunningTournament/${tournamentId}`,
    "GET",
    null,
    { username },
  );
  return response.data;
};

export const getSessionData = async (tournamentId: number, username: string): Promise<SessionDataResponse> => {
  const queryParams = encodeQueryParams({ tournament_id: tournamentId });

  const headers = {
    username,
  };

  const response = await customFetch(
    `${PUBLIC_SOCIAL_TOURNAMENT_URL}/getSessionData/${tournamentId}?${queryParams}`,
    "GET",
    null,
    headers,
  );
  return response;
};

export const playerResults = async (apiURL: string, token: string): Promise<PlayerResultsResponse> => {
  try {
    // First, try request without the Authorization header
    const response = await fetch(`${apiURL}/player/results`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        auth_token: await getJWTToken(),
      }),
    });

    if (!response.ok) {
      let errorMessage = "Failed to fetch player results";
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch {
        errorMessage = `${errorMessage}. Status: ${response.status} - ${response.statusText}`;
      }

      console.error(`Error fetching player results: ${errorMessage}`);
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in playerResults:", error);
    if (error instanceof Error) {
      throw new Error(`Player results request failed: ${error.message}`);
    }
    throw error;
  }
};

export const fetchPlayerResults = async (tournamentId: number, username: string): Promise<PlayerResultsResponse> => {
  try {
    const sessionData = await getSessionData(tournamentId, username);

    if (!sessionData.success || !sessionData.data?.apiURL || !sessionData.data?.token) {
      throw new Error("Invalid session data received");
    }

    const { apiURL, token } = sessionData.data;

    try {
      new URL(apiURL);
    } catch {
      throw new Error(`Invalid API URL received: ${apiURL}`);
    }

    return await playerResults(apiURL, token);
  } catch (error) {
    console.error("Error in fetchPlayerResults:", error);
    if (error instanceof Error) {
      throw new Error(`Failed to fetch player results: ${error.message}`);
    }
    throw error;
  }
};

export const verifyResult = async (tournamentId: number, username: string): Promise<VerifyResultResponse> => {
  try {
    const body = {
      tournament_id: tournamentId,
    };

    const response = await customFetch(`${PUBLIC_SOCIAL_TOURNAMENT_URL}/verifyResult`, "POST", body, { username });

    // Handle 204 & regular response
    if (response.success && !response.data) {
      return {
        success: true,
        message: "Tournament result verified successfully",
        data: {
          score: 0,
          verified: true,
          isDailySpinsLimitReached: false,
        },
      };
    }

    return response;
  } catch (error) {
    console.error("Error in verifyResult:", error);
    if (error instanceof Error) {
      throw new Error(`Failed to verify tournament result: ${error.message}`);
    }
    throw error;
  }
};
