function getSwedishOrdinal(number: number): string {
  if (isNaN(number) || number < 1) {
    return "Invalid input";
  }

  const lastDigit = number % 10;
  const secondLastDigit = Math.floor((number % 100) / 10);

  if (secondLastDigit === 1) {
    return number + ":e";
  } else {
    switch (lastDigit) {
      case 1:
        return number + ":a";
      case 2:
        return number + ":a";
      default:
        return number + ":e";
    }
  }
}

function getEnglishOrdinal(number: number): string {
  if (isNaN(number) || number < 1) {
    return "Invalid input";
  }

  const lastDigit = number % 10;
  const secondLastDigit = Math.floor((number % 100) / 10);

  if (secondLastDigit === 1) {
    return number + "th";
  } else {
    switch (lastDigit) {
      case 1:
        return number + "st";
      case 2:
        return number + "nd";
      case 3:
        return number + "rd";
      default:
        return number + "th";
    }
  }
}

export function getOrdinal(number: number, language: string): string {
  const mappers: Record<string, (number: number) => string> = {
    sv: getSwedishOrdinal,
    en: getEnglishOrdinal,
  };

  const mapper = mappers[language];
  if (mapper) {
    return mapper(number);
  } else {
    return "Language not supported";
  }
}
