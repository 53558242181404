<script lang="ts">
  export let content: string = "";

  // Check if content contains HTML tags
  $: isHTML = content && /<[a-z][\s\S]*>/i.test(content);

  // This is a hack that we might not need for production environment
  // For the demo, we have to add referrerPolicy to avoid CORS errors on images
  // Function to add referrerpolicy to img tags
  function addReferrerPolicy(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const images = doc.getElementsByTagName("img");
    for (const img of images) {
      img.setAttribute("referrerpolicy", "no-referrer");
    }
    return doc.body.innerHTML;
  }

  $: processedContent = isHTML ? addReferrerPolicy(content) : content;
</script>

{#if content}
  {#if isHTML}
    <div class="prize-display">
      {@html processedContent}
    </div>
  {:else}
    <div class="prize-display">
      {content}
    </div>
  {/if}
{/if}

<style>
  .prize-display {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .prize-display :global(img) {
    display: block;
    max-width: 100%;
    height: auto;
  }
</style>
