<script lang="ts">
  import { getBucketLink } from "$lib/common/helpers/image";
  import Icons from "$lib/main/components/Icons.svelte";
  import { formatValue } from "$lib/common/helpers/formatValue";

  export let operatorItem: any;
  export let rankingMode: string = "";
  export let type: string = "";
  export let title: string = "";
  export let timeUnit: string = "min";
</script>

<a
  href={`/svenska-casinon/${operatorItem.nameLower}`}
  data-element={type}
  data-operator={operatorItem.nameLower}
  data-type={title}
  data-compare-pos={operatorItem.rank}
>
  <div
    class="container"
    style={`background-color: rgba(${operatorItem.visuals?.primaryColor || "255,255,255,1"}); color: rgba(${operatorItem.visuals?.textColor || "255,255,255,1"})`}
  >
    <div class="flex">
      <div class="position">
        {operatorItem.rank}
      </div>
      <div class="img-container">
        <img
          alt={operatorItem.name}
          src={`${getBucketLink()}operators/${operatorItem.visuals?.logo}`}
        />
      </div>
      <div class="score">
        {#if rankingMode === "ratings"}
          {operatorItem.score.toFixed(1)}
        {:else}
          {formatValue(operatorItem.score, timeUnit)}
        {/if}
      </div>
    </div>
    <div class="arrow">
      <Icons width="20px" height="20px" name="chevron-right"></Icons>
    </div>
  </div>
</a>

<style>
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 0;
  }
  .flex {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .score {
    font-size: 16px;
    font-weight: 900;
    line-height: 20.8px;
  }
  .img-container > img {
    max-width: 105px;
    max-height: 27px;
  }
  .img-container {
    display: flex;
    min-width: 105px;
    max-width: 125px;
    padding: 12px 0;
  }
  .position {
    display: flex;
    justify-content: center;
    min-width: 32px;
    padding: 12px 7px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .arrow {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    margin-right: 8px;
    color: white;
    padding: 6px;
    display: flex;
    height: 32px;
  }
</style>
